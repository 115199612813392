@import "~antd/lib/style/themes/default.less";

@super-light-gray: #f0f0f0;

body {
  font-family: "Avenir", "Courier New", sans-serif;
}

#footer {
  padding: 2em;
}

.debug {
  border: 1px solid red;
}

.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
}

.horizontal-scroll-parent {
  text-align: -webkit-center;
  overflow: auto;
  .hide-scroll;
}

.horizontal-scroll-child {
  display: flex;
  justify-content: center;
  width: fit-content;
  overflow-x: scroll;
  .hide-scroll;
}

.issue-preview-card {
  margin: 5px 5px;
  .ant-card-body {
    padding: 15px;
    text-align: center;
  }
  a:hover {
    color: @blue-7;
    text-decoration: underline;
  }
}

#more-issues-card {
  min-height: 150px;
  width: 130px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#web-header {
  text-align: center;
  letter-spacing: 0.4px;
  border-bottom: 1px solid lightgray;
  box-shadow: black;

  position: relative;
  top: 0px;
  left: 0;
  right: 0;
  background-color: white;
  width: 100%;
  z-index: 100;
  h1 {
    font-size: 40px;
    font-weight: bolder;
    letter-spacing: 2px;
    margin: 0;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    border-bottom: unset;
  }
  .ant-menu-horizontal > .ant-menu-item,
  .ant-menu-horizontal > .ant-menu-submenu {
    border-bottom: unset;
  }
  a:hover {
    color: dimgray;
  }
  img {
    height: 60px;
    width: auto;
    margin-top: 75px;
    margin-bottom: 30px;
  }
}

#header-top-nav {
  position: fixed;
  border-bottom: unset;
  text-align: center;

  width: 100%;
  #spacer {
    margin: 0 5%;
  }
}

#header-section-nav {
  border-bottom: 1px solid black;
  text-align: center;
}

.social-links {
  display: flex;
  justify-content: center;
  margin: 0px 0;
  float: left;
  li {
    margin: 0 10;
  }
}

.general-menu {
  display: flex;
  justify-content: center;
  right: 0;
  margin-left: auto;
}

#mobile-header {
  padding: 5px 0;
  border-bottom: 1px solid @super-light-gray;
  position: fixed;
  top: 0;
  z-index: 100;
  h1 {
    letter-spacing: 2px;
  }
  #social-links {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    li {
      margin: 0 10%;
    }
  }
}

#searchbar {
  padding-top: 5px;
  text-align: center;
  margin: 5px;
}

#issue-carousel {
  border-bottom: 1px solid lightgray;
  margin: 0 20px 0 20px;
  .loading {
    width: 100px;
  }
  overflow: hidden;
}

.page-content {
  margin: 30px 20%;
}

#issue-title {
  min-font-size: 14px;
  font-size: 1vw;
  text-align: center;
  h1 {
    letter-spacing: 2px;
    margin-bottom: 0;
  }
}

@featured-article-img-padding: 30px;
.featured-article-preview-base(@width, @height, @padding) {
  width: @width + @padding;
  padding: @padding / 2;
  padding-bottom: 0;
  margin: 5px;
  .ant-card-cover {
    width: @width;
    height: @height;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .ant-card-body {
    padding: @padding / 2 @padding / 3;
  }
  img {
    width: @width;
    height: auto;
  }
  a {
    white-space: initial;
  }
  a:hover {
    text-decoration: underline;
  }
  p {
    margin-bottom: 0;
  }
}
.featured-article-preview-parent {
  display: flex;
}
.featured-article-preview {
  .featured-article-preview-base(230px, 180px, @featured-article-img-padding);
}
//#list-preview {
//  img {
//    width: 100%;
//    margin-bottom: 3px;
//  }
//}

@article-preview-img-size: 170px;
.article-preview {
  margin: 15px 0;
  .ant-card-body {
    display: flex;
  }
  .article-image-child {
    display: flex;
    justify-content: center;

    overflow: hidden;

    width: @article-preview-img-size;
    min-width: @article-preview-img-size;
  }
  .article-info {
    width: 100%;
    margin-left: 20px;
    h2 {
      margin-bottom: 0;
    }
    p {
      margin-top: 3px;
      margin-bottom: 3px;
    }
  }
  a:hover {
    text-decoration: underline;
  }
}

@fte-article-width: 70%;
@max-article-height: 550px;
@issue-cover-width: 250px;
#fte {
  display: flex;
  align-items: center;
  padding: 10px 10%;
  #fte-article-parent {
    width: @fte-article-width - 10%;
    #fte-article {
      overflow: hidden;
      padding-bottom: 15px;
      .ant-card-body {
        padding: 24px 32px;
        max-height: @max-article-height;
        overflow: auto;
      }
      #by {
        text-align: right;
      }
    }
  }

  #issue-cover-parent {
    margin-right: 30px;
    width: 100% - @fte-article-width;
    #img-parent {
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      img {
        width: @issue-cover-width;
      }
    }
  }
}

.info-page {
  font-size: 16px;
  padding: 0 5%;
  h1 {
    text-align: center;
    font-weight: bolder;
    margin-bottom: 5px;
  }
  h2,
  h3 {
    text-align: center;
  }
  pre {
    text-align: center;
  }
}

#home-page {
  #bulletin-board {
    margin-top: 30px;
    text-align: center;
    text-decoration: underline;
    letter-spacing: 1px;
  }
}

#about-page {
  h1 {
    text-transform: uppercase;
  }
  h2,
  h3 {
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .info-page;
  #logo {
    text-align: -webkit-center;
    img {
      height: 80px;
      width: auto;
    }
  }
  img {
    width: 100%;
    height: auto;
  }
}

#donate-page {
  h1 {
    margin-bottom: 15px;
  }
  .info-page;
}

#subscribe-page {
  text-align: center;
  a {
    text-decoration: underline;
  }
}

#alumni-page {
  text-align: center;
  h1 {
    margin-bottom: 0;
  }
}

#article-page {
  .article-header {
    font-size: initial;
    text-align: center;
    margin: 20px 0;
    h1 {
      font-weight: bold;
      letter-spacing: 1px;
    }
    h2,
    .collaborators,
    p {
      font-style: italic;
      margin: 10px 0;
    }
    .collaborators {
      a:hover {
        text-decoration: underline;
      }
    }
  }
  .article-content {
    font-family: "Georgia", serif;
    font-size: 18px;
    max-width: 700px;
    margin: 0 auto 5em;
  }
  @img-width: 70%;
  img {
    width: 100%;
  }
  p {
    text-align: unset;
  }
}

#section-page {
  h1 {
    text-align: center;
    margin: 30px 0;
    margin-top: 20px;
  }
}

.admin-article-button {
  text-align: center;
  .ant-btn {
    margin: 0 10px;
  }
}

.loading {
  //background-color: pink;
  padding: 10% 5%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ant-pagination {
  text-align: center;
}

#ckeditor-breadcrumb {
  a:hover {
    color: @blue-4;
  }
}
#ckeditor-modal {
  #ckeditor-previews {
    #folder-previews {
      #add-folder-input {
        margin-bottom: 10px;
      }
      .folder-preview:hover {
        background-color: @super-light-gray;
      }
    }
    #img-previews {
      @num-columns: 7;
      @img-padding: 10px;
      @img-length: 120px;
      @img-preview-width: @img-length + (@img-padding*2);
      @img-preview-margin: 5px;
      .img-previews-child {
        width: (@img-preview-width + (@img-preview-margin*2)) * @num-columns;
        margin: 0 auto;
      }

      .ant-collapse-content-box {
        overflow: auto;
      }

      .img-preview {
        display: inline-block;

        width: @img-length + (@img-padding*2);
        padding: @img-padding @img-padding 5px;
        margin: @img-preview-margin;

        border: solid 1px darkgray;
        border-radius: 10px;
        .img-preview-img {
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;

          width: @img-length;
          height: @img-length;
          margin-bottom: 3px;

          border-radius: 10px;
          img {
            height: @img-length;
          }
        }
        .img-preview-name {
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .img-preview:hover {
        background-color: @super-light-gray;
      }
    }
  }
  #selected-img {
    font-size: 16px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 800px) {
  .page-content {
    margin: 100px 7%;
  }
  #issue-title {
    font-size: 2.5vw;
  }
  #issue-carousel {
    margin-top: 59px;
  }
  .issue-preview-card {
    .issuu-link {
      display: none;
    }
  }
  .featured-article-preview {
    .featured-article-preview-base(200px, 130px, @featured-article-img-padding);
  }

  #fte {
    .ant-collapse {
      width: 100%;
    }
    #fte-article {
      width: 100%;
      margin-right: 0;
    }
  }

  .article-preview {
    .ant-card-body {
      display: inherit;
      .article-image-parent {
        height: 150px;
        overflow: hidden;
        margin-bottom: 10px;
        .article-image-child {
          width: auto;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
      .article-info {
        margin-left: 0;
      }
    }
  }

  #article-page {
    .article-header {
      h1 {
        font-size: 24px;
      }
      h2 {
        font-size: 20px;
      }
    }
    .article-content {
      font-size: 16px;
    }
  }

  #issues-page {
    .ant-row {
      overflow: scroll;
    }
  }

  #subscribe-page {
    .ant-form-item-label {
      margin-left: 0;
    }
  }

  #alumni-page {
    .ant-form-item-label {
      margin-left: 0;
    }
  }
}

@primary-color: @black;@link-color: rgba(0,0,0,0.9);@success-color: @green-6;@warning-color: @orange-6;@error-color: @red-6;@font-size-base: 14px;@heading-color: @black;@text-color: @black;@text-color-secondary: rgba(0,0,0,0.9);@disabled-color: rgba(0,0,0,0.5);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);