@import "~antd/lib/style/themes/default.less";

.add {
  display: flex;
  .ant-upload {
    color: white;
  }
}
.admin-button {
  margin: 20px auto;
}

#list-page {
  #recent-list {
    text-align: center;
    margin-bottom: 2em;
  }
}

#issue-page {
  #issue-form {
    margin: 1em 0;
  }
  #issue-info {
    display: flex;
    #issue-articles {
      margin: 0 20px;
      a:hover {
        color: @blue-4;
      }
    }
  }
}

#article-page {
  margin-bottom: 70px;
  .ant-row {
    margin-bottom: 12px;
  }
  #footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    text-align: center;
    padding: 10px;
    border-top: 1px solid @border-color-base;
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  .editor {
    .ckeditor {
      .ck-content {
        font-family: "Georgia", serif;
      }
    }
  }
}

#ckeditor-modal-button {
  margin-bottom: 10px;
}

#pages-page {
  #select {
    text-align: -webkit-center;
    margin: 10px 0;
    .ant-select {
      margin-left: 10px;
    }
  }
  #editor-parent {
    padding: 0 20%;
  }
}

.editor {
  .ckeditor {
    .ck-content {
      min-height: 100px;
      max-height: 800px;
    }
    .ck-editor__main {
      background-color: pink;
    }
  }
}

.login-admin {
  display: fixed;
  margin-left: 250px;
  margin-top: 200px;
}

.login-admin-field {
  width: 50%;
  margin-top: 10px;
}

.login-admin-button {
  margin-top: 10px;
}

@primary-color: @black;@link-color: rgba(0,0,0,0.9);@success-color: @green-6;@warning-color: @orange-6;@error-color: @red-6;@font-size-base: 14px;@heading-color: @black;@text-color: @black;@text-color-secondary: rgba(0,0,0,0.9);@disabled-color: rgba(0,0,0,0.5);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);