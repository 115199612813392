@import '~antd/lib/style/themes/default.less';

.ckeditor {
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: #000;
    font-weight: 500;
  }
  h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
}

.indy-poem {
  font-style: italic;
  line-height: normal;
  margin: 0.5em 0em;
}
.indy-lines {
  line-height: normal;
  margin: 0.2em 0em;
}
.indy-padded {
  padding: 0 20%;
}

.text-huge {
  font-size: 1.8em;
}
.text-big {
  font-size: 1.4em;
}
.text-small {
  font-size: .85em;
}
.text-tiny {
  font-size: 0.7em;
}

.todo-list {
  list-style: none;
}

code {
  background-color: hsla(0,0%,78%,.3);
  padding: .15em;
  border-radius: 2px;
}

blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: 5px solid #ccc;
}

.masthead {
  column-count: 3;
  column-gap: 15px;
  column-fill: auto;
  text-align: center;
  h4 {
    padding: 1.33em 0;
    padding-bottom: 0.25em;
    margin: 0;
  }
  p {
    margin: 0;
  }
  .masthead-block {
    display: block;
    break-inside: avoid-column;
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
  }
}

@primary-color: @black;@link-color: rgba(0,0,0,0.9);@success-color: @green-6;@warning-color: @orange-6;@error-color: @red-6;@font-size-base: 14px;@heading-color: @black;@text-color: @black;@text-color-secondary: rgba(0,0,0,0.9);@disabled-color: rgba(0,0,0,0.5);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);